import React, { useState, useEffect } from 'react';
import { List, ListItem, Typography, IconButton, Grid } from '@mui/material';
import plural from 'utils/pluralizeProducts';
import { useSetCart } from 'contexts';
import convertCentToDollar from 'utils/convertCentToDollar';
import { copyText } from 'language';
import { arrayOf, bool, shape } from 'prop-types';
import { Box } from '@mui/system';
import TextLoader from 'components/TextLoader';
import { DeleteOutline } from '@mui/icons-material';
import * as cartUtils from 'utils/cart';
import financialCalculators from 'utils/financialCalculators/financialCalculators';
import getExchangeTotal from 'utils/CartFinancials/getExchangeTotal';
import FinanceListItem from 'components/FinanceListItem';
import SnackbarMessage from 'components/SnackbarMessage/SnackbarMessage';
import PromoSelection from './components/PromoSelection/PromoSelection';
import GiftCard from './components/GiftCard';

const CartTotals = ({ cart, editGiftCards, editCoupons }) => {
  const lineHeight = 2;
  const setCart = useSetCart();
  const [totalPrice, setTotalPrice] = useState('$0.00');
  const [promoCodeTotals, setPromoCodeTotals] = useState('$0.00');
  const [discountCodes, setDiscountCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [giftCardTotals, setGiftCardTotals] = useState('$0.00');
  const [errorMessage, setErrorMessage] = useState('');
  const [exchangeTotal, setExchangeTotal] = useState(0);
  const [rejectedPromos, setRejectedPromos] = useState([]);

  useEffect(() => {
    setGiftCardTotals(convertCentToDollar(financialCalculators.getGiftCardTotals(cart)));
    setPromoCodeTotals(convertCentToDollar(financialCalculators.getCartDiscounts(cart)));
    setExchangeTotal(convertCentToDollar(getExchangeTotal(cart)));
    setTotalPrice(convertCentToDollar(financialCalculators.calculatePreDiscountedPrice(cart)));
    financialCalculators.populateAppliedDiscountCodes({ cart, rejectedPromos, setDiscountCodes });
    setRejectedPromos(financialCalculators.getRejectedPromos(cart));
    // TODO: Remove console.log
    // eslint-disable-next-line no-console
    console.log(discountCodes);
  }, [cart]);

  useEffect(() => {
    financialCalculators.populateAppliedDiscountCodes({ cart, rejectedPromos, setDiscountCodes });
  }, [rejectedPromos]);

  return (
    <>
      <List sx={{ mb: 2 }}>
        <FinanceListItem
          label={`Price (${cart?.totalLineItemQuantity || 0} item${
            plural(cart?.totalLineItemQuantity || 0) ? 's' : ''
          })`}
          value={totalPrice}
        />
        <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
          <Typography variant="list_item" component="p" align="left">
            {copyText.Cart.CartTools.giftCards}
            {editGiftCards && <GiftCard />}
          </Typography>
          <Typography variant="list_item" component="p" align="right">
            -{giftCardTotals}
          </Typography>
        </ListItem>
        <FinanceListItem
          label={copyText.Cart.CartTools.promos}
          value={`-${promoCodeTotals}`}
          isPromo
        />
        {cart?.is_exchange_order && (
          <FinanceListItem
            label={copyText.Orders.Actions.exchangeCredit}
            value={`-${exchangeTotal}`}
            isPromo
            showDefinition
            definition={copyText.Cart.CartTools.exchangeCreditDefinition}
          />
        )}
        {discountCodes.length > 0 && (
          <Box sx={{ backgroundColor: 'lightPurple20', p: 1, borderRadius: '5px' }}>
            <ListItem sx={{ p: 0, justifyContent: 'space-between', mb: 1 }}>
              <Typography
                component="h3"
                variant="h4"
                align="left"
                fontSize={14}
                sx={{ lineHeight, fontWeight: 'bold', fontSize: 12 }}
              >
                {copyText.Cart.CartTools.appliedDiscounts}:
              </Typography>
            </ListItem>

            {discountCodes.map((discountCode) => {
              return (
                <ListItem key={discountCode.id} sx={{ p: 1, justifyContent: 'space-between' }}>
                  <Box display="flex" alignItems="center" width="100%">
                    {editCoupons && financialCalculators.isDiscount(discountCode) && (
                      <Grid container>
                        <Grid item xs={2}>
                          <IconButton
                            sx={{ p: 0, mr: 1 }}
                            onClick={() =>
                              financialCalculators.removeDiscountCode({
                                cart,
                                code: discountCode,
                                setCart,
                                setLoading,
                                setErrorMessage,
                              })
                            }
                          >
                            <TextLoader loading={loading} size={20} text={<DeleteOutline />} />
                          </IconButton>
                        </Grid>
                        <Grid item xs={6.5}>
                          <Typography align="left" variant="list_item">
                            {discountCode.display_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography align="right" variant="list_item">
                            -{discountCode.totalCumulativeDiscount}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                    {editCoupons && !financialCalculators.isDiscount(discountCode) && (
                      <PromoSelection
                        promo={discountCode}
                        loading={loading}
                        setLoading={setLoading}
                        rejectedPromos={rejectedPromos}
                        setMessage={setErrorMessage}
                      />
                      // eslint-disable-next-line indent
                    )}
                  </Box>
                </ListItem>
              );
            })}
          </Box>
        )}
        {rejectedPromos?.length > 0 && !cartUtils.cartContainsOutletItems(cart) && (
          <Box sx={{ backgroundColor: 'grayUtility', p: 1, mt: 1, borderRadius: '5px' }}>
            <ListItem sx={{ p: 0, justifyContent: 'space-between', mb: 1 }}>
              <Typography
                component="h3"
                variant="h4"
                align="left"
                fontSize={14}
                sx={{ lineHeight, fontWeight: 'bold', fontSize: 12 }}
              >
                {copyText.Cart.CartTools.availablePromos}:
              </Typography>
            </ListItem>
            {rejectedPromos?.map((promo) => {
              return (
                <ListItem key={promo?.id} sx={{ p: 1 }}>
                  <PromoSelection
                    promo={promo}
                    loading={loading}
                    setLoading={setLoading}
                    setMessage={setErrorMessage}
                    rejectedPromos={rejectedPromos}
                  />
                </ListItem>
              );
            })}
          </Box>
        )}
      </List>
      <SnackbarMessage message={errorMessage} setMessage={setErrorMessage} />
    </>
  );
};

CartTotals.propTypes = {
  cart: shape({
    lineItems: arrayOf(shape({})),
    taxedPrice: shape({}),
  }),
  editGiftCards: bool,
  editCoupons: bool,
};

CartTotals.defaultProps = {
  cart: null,
  editGiftCards: true,
  editCoupons: true,
};
export default CartTotals;
