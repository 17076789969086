const copyText = {
  App: {
    cancel: 'Cancel',
    edit: 'Edit',
    delete: 'Delete',
    yes: 'Yes',
    send: 'Send',
    no: 'No',
    email: 'Email',
    store: 'Store',
    agent: 'Agent',
    title: 'Title',
    pageURL: 'Page URL',
    none: 'None',
    missing: 'Missing',
    close: 'Close',
    available: 'Available',
    retry: 'Retry',
    print: 'Print',
    date: 'Date',
    refresh: 'Refresh',
    price: 'Price',
    item: 'Item',
    items: 'Items',
    next: 'Next',
    back: 'Back',
    skip: 'Skip',
    loading: 'Loading...',
    questionMark: '?',
    connectToClover: 'Connect to Clover',
    details: 'Details',
    quantityAbbreviated: 'Qty',
    add: 'Add',
    errorOccurred: 'An Error Occurred',
    transaction: 'Transaction',
    orderNumber: 'Order #',
    customerSupportPhone: '888-848-8456',
    customerSupportCenter: 'Purple Customer Support Center',
    returnWarrantyPolicy: 'For Return and Warranty policy',
    pleaseVisitUrl: 'please visit',
    returnsURL: 'www.purple.com/returns',
    reason: 'Reason',
    chooseStore: 'Choose Store',
    continue: 'Continue',
    yesContinue: 'Yes, Continue',
    saving: 'Saving...',
    updating: 'Updating...',
    savedUpdatesSuccess: 'Successfully saved updates',
    failure: 'Failure',
    pendingPaymentWarning:
      'There are unresolved pending payments on this cart that must be resolved or voided before proceeding.',
    notRightNowLoadNewCart: 'Not right now. Load a new cart.',
    openCheckout: 'Open Checkout',
    warning: 'Warning',
    provider: 'Provider',
    id: 'ID',
    genericError: 'Please try again. If the issue persists, contact Customer Care for assistance.',
  },
  DevTools: {
    devTools: 'Dev Tools',
    addMattressToCart: 'Add rejuvenate mattress to cart',
    addMattressBaseToCart: 'Add premium plus smart base to cart',
    addShippingAddressToCart: 'Add store shipping address to cart',
    cloverTokenExpired: 'Clover token has been expired',
    expireCloverToken: 'Expire Clover Access Token',
    causeCloverRefreshFailure: 'Set up Clover Refresh Failure',
    cloverRefreshFailure:
      'Clover refresh token has been removed, trigger a token refresh and it should fail now.',
    expireOktaToken: 'trigger Okta token refresh (requires page reload)',
  },
  Clover: {
    refreshingCredentials: 'Refreshing Clover Credentials...',
    credentialsRefreshed: 'Clover Credentials Refreshed',
    errorRefreshingCredentials:
      'Unable to refresh Clover credentials. Please reconnect to Clover to get new credentials.',
  },
  Agent: {
    notAvailable: 'N/A',
  },
  Layouts: {
    SearchLayout: {
      filters: 'Filters',
      search: 'Search',
      legend: 'Legend',
    },
    TableLayout: {
      noDataFound: 'No Data Found.',
    },
  },
  Feedback: {
    sendFeedback: 'Send Feedback',
    linkWarning: 'You are about to leave the MPOS application. Are you sure you want to continue?',
    continue: 'Continue',
    tryAgain: 'Please try again or contact IT for assistance.',
  },
  Terminal: {
    noDeviceSelected: 'No Device Selected',
    connected: 'connected',
  },
  Cart: {
    CartTools: {
      ChargeAfter: {
        checkoutWithChargeAfter: 'Checkout With Purple Financing',
        loanApplicationDetailsSaved:
          'Loan application progress has been saved to the cart. You can choose to apply them later if you wish.',
        verifyContactInfoAndShipping: 'Verify Contact Info & Address',
        verifyBilling: 'Verify Billing Address',
        createApplication: 'Create Application',
        mirrorShipping: 'Same as shipping',
        line1: 'Street Address Line 1',
        line2: 'Street Address Line 2',
        city: 'City',
        postalCode: 'Postal Code',
        goBack: 'Go Back',
        correctInfo: 'This information is correct',
        sendLink: 'Send Link',
        updateIncorrectInfo:
          'If this is incorrect, update the contact information to be used in the application before proceeding.',
        transmitMethodQuery: 'How would you like to receive a link to your application?',
        transmitMethod: 'A link to the application will be sent in an ',
        to: 'to',
        applicationMethod: 'Application Method',
        sms: 'SMS',
        email: 'Email',
        sendLinkToCustomer: 'Sending link to customer...',
        offersForCustomer: 'Offers for customer',
        waitingForAppCompletion: 'Waiting for your customer to complete their application...',
        appCompletionInstructions: 'When they are finished, push the button below to proceed.',
        patienceIsAVirtue:
          'If your customer does not see the email, have them check the spam folder. Emails can sometimes take a few minutes to arrive, we appreciate your patience.',
        updateDetailsResendLink: 'resend link',
        waitingForOrderCreation:
          'Hang tight! We are applying the loan to the purchase total and creating the order...',
        firstName: 'First Name',
        lastName: 'Last Name',
        phoneNumber: 'Phone Number',
        applicationCompleted: 'Application Completed!',
        createOrder: 'Create Order',
        retrieveDetailsCreateOrder: 'Retrieve Loan Details & Create Order',
        sendLinkFailure:
          'It looks like there was a problem sending your application. Double check that the applicant information is correct and try again. If the issue persists, please refer to the troubleshooting guide in the Purple Playbook.',
        financingAvailable: 'Financing available for orders over $100',
        confirmCancelWarning:
          'Due to the possibility of cart modifications not matching the application details sent to ChargeAfter, the application ID will be removed from the cart and the customer will have to fill out a new lender application to checkout with ChargeAfter.',
        confirmCancelCheckout: 'Are you sure you want to go back?',
      },
      CartHistory: {
        searchByEmail: 'Search By Email',
        cartDetails: 'Cart Details',
        loadThisCart: 'Load this cart',
        selectCartToSeeDetails: 'Select a cart to view its details',
        searchFailed: 'Cart history search failed due to an error',
        noCartsFound: 'No Carts Found',
        history: 'Carts History',
        filters: 'Filters',
        draftOrderFilter: 'Draft Order',
        agentEmail: 'Agent Email',
        updateFilters: 'Update',
        clearSearch: 'Clear Search',
      },
      DraftOrder: {
        sendDraftTitle: 'Send Draft Order',
        sendDraftButton: 'Send Draft',
        markingCartAsDraft: 'Marking cart as a draft...',
        customerFirstName: 'Customer First Name',
        sendDraft: 'Sending draft to customer email...',
        markAsDraftFailure: 'Failed to mark cart as a draft.',
        sendDraftSuccess: 'Successfully sent draft order',
        sendDraftFailure: 'Failed to send draft order email.',
        advisoryOne:
          'This cart will be marked as a draft and details will be emailed to the customer, including the cart details, and a link for them to check out.',
        advisoryTwo:
          'The customer will not be able to edit the products or discounts in the cart. If they want to change the order, they will need to contact a sales representative.',
        addNote: 'Add a note',
        addNotePlaceholder:
          'It was nice to talk with you! We put together a dreamy order just waiting for your final go-ahead to process.\nIf you’ve spotted something you’d like to add, contact your local showroom.',
        charLimit: 'Character Limit',
        successFullySent: 'Draft successfully sent!',
        customerInfo: 'Customer Info',
        addCustomerInfo: 'Add Customer Info',
        deliveryAddress: 'Delivery Address',
        deliveryAddressWarning:
          'Please enter a shipping address to view all available shipping methods and delivery costs. Click "Continue to Delivery Methods" again to proceed without an address or enter one now to see all shipping options in the next step.',
        continueToDeliveryAddress: 'Continue to Delivery Address',
        deliveryMethods: 'Delivery Methods',
        continueToDeliveryMethods: 'Continue to Delivery Methods',
        reviewDraftOrder: 'Review Draft Order',
        emailIsNotValid: 'Email is not valid',
        continueToReview: 'Continue to Review',
        invalidEmail: 'Invalid email address',
        draftSent: 'Draft Sent',
        draftSentMessageOne:
          'The draft was successfully sent. Select “Load a New Cart” if you would like to save and close this cart to start a new transaction or are working with a new customer.',
        draftSentMessageTwo:
          'Select “Continue” if you would like to stay within this transaction. Any modifications to the order will update the draft details to the customer and a new draft will need to be sent.',
      },
      salesAgent: 'Sales Agent',
      noAgentSelected: 'No agent selected',
      selectSalesAgent: 'Please select a sales agent',
      deliveryAddress: 'Delivery Address',
      missingCloverToken: 'Log in to Clover to checkout.',
      missingCloverDevice: 'Select a Clover terminal to checkout.',
      sendDraft: 'Send draft to customer email',
      dateModified: 'Date Modified',
      discounts: 'Discounts',
      customerName: 'Customer Name',
      DraftOrderText: 'Draft Order',
      giftCards: 'Gift Cards',
      promos: 'Promo(s)',
      shipping: 'Shipping',
      recyclingFee: 'Recycling Fee',
      recyclingFeeExplanation:
        'State law requires that we charge a recycling fee when removing mattresses',
      shippingAndTaxWillBeCalculated: 'Shipping and Tax will be calculated at checkout.',
      shippingAddressRequired: 'Shipping address required',
      shippingMethodWarning:
        'Shipping method(s) were modified. Please verify your shipping method(s).',
      cost: 'Free',
      tax: 'Tax',
      addShippingAddress: 'Add Shipping Address',
      updateCart: 'Update Cart',
      duplicateError:
        'This item is already in the cart from a different shop. Please remove it if you want to add it from this shop.',
      loadNewCart: 'Load a New Cart',
      newCartLoaded: 'New Cart Loaded!',
      verifyClearCart: 'Are you sure?',
      clearCartDialog:
        'The current cart will be saved and a fresh cart will be loaded. The saved cart can be found and reloaded from Drafts.',
      updateEmail: 'Update Email',
      addEmail: 'Add Customer Email',
      cartId: 'Cart ID',
      finalSale: 'Final Sale',
      floorModel: 'Floor Model',
      customerEmail: 'Customer Email',
      cartPreview: 'Cart Preview',
      viewCart: 'View Cart',
      closeCartPreview: 'Close cart preview',
      editEmail: 'edit email address',
      fullName: 'Full Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      phone: 'Phone',
      phoneNumber: 'Phone Number',
      country: 'Country',
      streetAddressSteNumber: 'Street Address & Applicable Unit Number',
      additionalStreetInfo: 'Additional Street Info',
      streetAddressOnly: 'Street Address',
      state: 'State',
      cityStateZip: 'City, State, Zip',
      city: 'City',
      postalCode: 'Postal Code',
      customerFirstName: 'Customers First Name',
      customerLastName: 'Customers Last Name',
      add: 'ADD',
      edit: 'EDIT',
      shippingAddress: 'Shipping Details',
      shippingAddressInput: 'Shipping Address',
      searchForAddress: 'Search for an address',
      removeFromCart: 'remove item from cart',
      customerInfo: 'Customer Info',
      subtractOne: 'subtract one',
      addOne: 'add one',
      email: 'Email',
      view: 'View',
      shippingMethod: 'Shipping Method',
      updateCartFailure: 'Failed to update cart',
      noSelectedShippingMethod: 'No shipping method found for: ',
      sameSkuShippingMethods: '*Has same shipping method as item with the same SKU.',
      pickupFrom: 'Pickup From',
      removeMattress: 'Remove Mattress',
      update: 'Update',
      addressStatus: 'Address Status',
      valid: 'Valid Address',
      unverified: 'Unverified Address',
      invalid: 'Invalid Address',
      changeStore: 'Change Store',
      pickupCarryOutOnly:
        'Available for orders where every line item shipping method is carry out or pickup.',
      useStoreAddress: 'Use Store Address',
      validAddressForWarranty: 'A valid customer address is required for warranty.',
      addressValidation:
        "We were not able to validate the customer's address. Are you sure you want to continue?",
      confirmAddress: 'Yes, this is a valid address',
      appliedDiscounts: 'Applied Promo Codes',
      availablePromos: 'Available Promos',
      applyGiftCard: 'Apply A Gift Card',
      giftCardNumber: 'Card Number',
      giftCardSecurityCode: 'Security Code',
      successfulGiftCard: 'Successfully applied gift card balance to cart!',
      failedGiftCard: 'Failed to apply gift card balance',
      failedAddToCart: 'Failed to add product to cart',
      failedShippingMethods: 'Failed to add default shipping method',
      applyBalance: 'Apply Balance',
      orderCreationFailed: 'Order Creation Failed',
      reason: 'Reason',
      canRetry: 'You can try to create the order again, or contact support for assistance.',
      viewPickupOptions: 'View Pickup Options',
      pickupAvailability: 'Pickup Availability',
      alwaysCallAhead:
        'It is advised to always call ahead to make sure the product is in stock before checking out.',
      selectLocation: 'Select a store to pickup from',
      phoneNumberMissing: 'Phone number not provided',
      addPickupToCart: 'Add pickup option to Cart',
      outOfStock: 'Out of Stock',

      cartsAssociatedToStores: 'Carts are associated with the store where they were created.',
      storeChangeWarning:
        'If you are working with a cart, changing store location will create a new cart associated to the new location. The original cart can then be found in the Drafts tab.',
      exchangeOrderCreationFailed: 'Exchange Order Creation Failed',
      paymentFailed: 'Payment failed, something went wrong.',
      unavailableForExchange: 'Unavailable For Exchange',
      returnedOrExchanged: '# Returned or Exchanged',
      viewOriginalOrder: 'View Original Order',
      exchangeOrders: 'Exchange Orders',
      exchangeCreditDefinition: 'The total value (including tax) of the exchanged items',
      note: 'Note',
      markAsFinalSale: 'Mark as Final Sale',
      asFinalSale: 'as Final Sale',
      markAsFinalSaleQuestionPartOne: 'Are you sure you want to',
      mark: 'Mark',
      unmark: 'Unmark',
      markingFinalSaleError: 'Something went wrong while marking the cart as final sale.',
      markAsFloorModel: 'Mark as Floor Model',
      asFloorModel: 'as Floor Model',
      markAsFloorModelQuestionPartOne: 'Are you sure you want to',
      markingFloorModelError: 'Something went wrong while marking the cart as floor model.',

      noCustomerInfoAdded: 'No customer information added.',
      noShippingInfoAdded: 'No shipping information added.',
    },
    CheckoutStepper: {
      checkout: 'Checkout',
      customerInfo: 'Customer Info',
      deliveryMethods: 'Delivery Methods',
      deliveryMethod: 'Delivery Method',
      selectDeliveryMethod: 'Select Delivery Method',
      removeMattress: 'Remove Mattress',
      pickUpLocation: 'Pick Up Location',
      shippingDetails: 'Shipping Details',
      reviewOrder: 'Review Order',
      paymentInfo: 'Payment Info',
      orderConfirmation: 'Order Confirmation',
      address: 'Address',
      phone: 'Phone',
      email: 'Email',
      orderSummary: 'Order Summary',
      orderNumber: 'Order #',
      placeOrder: 'Place Order',
      next: 'Next',
      back: 'Back',
      cancel: 'Cancel',
      close: 'Close',
      cancelWarning:
        'If you cancel, all previously authorized payments will be voided. Do you wish to cancel?',
      backWarning:
        'If you go back, all previously authorized payments will be voided. Do you wish to go back?',
      yesCancel: 'Yes, cancel and close modal',
      yesGoBack: 'Yes, go back',
      noContinue: 'No, continue checking out',
      voidError:
        'Something went wrong while voiding payments. If this error persists after retrying, please clear the cart and start over (any pending payments will be cleared and not be charged).',
      retryVoid: 'Retry',
      clearCart: 'Clear Cart',
      processExchange: 'Process Exchange',
      exchangeWarning:
        'Please verify all exchange information. Continuing to payment will create the exchange order and process any exchange refunds.',
    },
    receipt: {
      soldBy: 'Sold by',
      soldTo: 'Sold to',
      totalCaps: 'TOTAL',
      refundCaps: 'REFUND AMOUNT',
      pickList: 'Pick List',
      pickupAt: 'Pick up at',
      giftCardXX: 'Gift Card XX',
      endingIn: 'ending in XXXX',
      exchangeItems: 'Exchange Items',
      purchaseItems: 'Purchase Items',
      price: 'Price',
      amountRefunded: 'Amount Refunded',
      refundAmount: 'Refund Amount',
      exchangeCredit: 'Exchange Credit',
      subTotal: 'Subtotal',
      exchangeOrderNumber: 'Exchange Order Number',
      originalOrderNumber: 'Original Order Number',
      sku: 'SKU',
    },
    ItemList: {
      emptyCart: 'Your cart is empty',
    },
    CheckoutButtons: {
      failedToPrintReceipt: 'Failed to print receipt',
      amazon: 'amazon pay',
      express: 'Express Checkout',
      startOver: 'Done with this transaction and need to start over?',
      voidError:
        'Something went wrong while voiding payments. If this error persists after retrying, please clear the cart and start over (any pending payments will be cleared and not be charged).',
      retryVoid: 'Retry',
      clearCart: 'Clear Cart',
      paypal: {
        alt: 'PayPal',
      },
      affirmPaymentMethod: 'Affirm',
      proceed: 'Proceed to Checkout',
      noItemsToExchange: 'Please select at least one item to exchange',
      multiPayCheckout: 'Multi-Pay Checkout',
      affirm: 'Checkout with Affirm',
      paymentMethod: 'Payment Method',
      card: 'Card',
      cash: 'Cash',
      chargeAfterPaymentMethod: 'ChargeAfter',
      continueToChargeAfter: 'Continue to ChargeAfter',
      purpleFinancing: 'Purple Financing',
      continueToPurpleFinancing: 'Continue to Purple Financing',
      payInFull: 'Pay in full',
      amount: 'Amount',
      customPayment: 'Custom Amount',
      cancelWarning:
        'If you cancel, all previously authorized payments will be voided. Do you wish to continue?',
      backWarning:
        'If you go back, all previously authorized payments will be voided. Do you wish to continue?',
      yesCancel: 'Yes, cancel and go back',
      noContinue: 'No, continue checking out',
      authorizedPayments: 'Authorized Payments',
      processPayment: 'Process Payment',
      error: 'There was an error:',
      unknownPaymentError: 'Error. Please try again.',
      paymentsCompleted: 'Payment authorization completed!',
      splitPaymentLimit:
        'A maximum of 3 payment methods is permitted. The 3rd form of payment must cover the remaining unpaid amount.',
      remainingTotal: 'Remaining Total',
      placeOrder: 'Place Order',
      cartUpdated: 'Cart updated',
      invalidAmount: 'Invalid amount',
      paymentReceivedUpdatingCart: 'Payment received, updating cart...',
      failedToFindPendingPayments: 'Failed to find updated payments',
      creatingOrder: 'Creating order...',
      readyForPayment: 'Ready for payment...',
      awaitingResponse: 'Awaiting Response...',
      paymentCancelled: 'Payment cancelled',
      orderCreated: 'Order Created',
      checkout: 'Checkout',
      failedToUpdateCart: 'Failed to update cart',
      orderCreationFailedVoidedPayments:
        "There was a problem creating your order. But don't worry, we've voided your payment(s). Please try again.",
      orderCreationFailedVoidFailed:
        'There was a problem creating your order and we were unable to void your payment(s). Please contact support.',
      errCodesNonLeasableItems:
        'You can remove non-leasable items or void all pending payments and choose a different form of payment(s).',
      errCodesShippingMethodError: 'Change the selected shipping method(s) and try again.',
      errCodesNoShippingAddress: 'Void all payments, add a shipping address, and try again.',
      errCodesGiftCardBalance: 'Add additional forms of payment and try again.',
      errCodesPaymentUpdateFailure: `An error occurred after capturing a payment.
       If the erroneous payment is marked as 'Success', please try again.
      Otherwise, MPOS was unable to update the cart with the captured payment.
      If this is the case, please contact Customer Care for assistance in resolving this issue.`,
      errCodesPaymentCaptureFailure:
        'One or more pre-authorized payments were not processed and there may be a connectivity issue with the payment provider. Please try again in a few minutes. (MPOS will only retry with unsuccessful payments)',
      errCodesPaymentRetrievalFailure:
        'One or more processed payment details could not be retrieved from the payment provider and there may be a connectivity issue with the payment provider. Please try again in a few minutes. (MPOS will only retry with unsuccessful payments)',
      errCodesUnknownError:
        'An unknown error occurred. Please try again. If the problem persists, please contact Customer Care for assistance resolving this issue',
      errCodesPaymentCaptureFailureSuccessfulVoid:
        'An error occurred while attempting to capture payment during order creation. The payment was successfully voided and the cart has been updated. Please retake payment and create the order.',
      orderCreationFailed: 'Order creation failed',
      reason: 'Reason',
      resolution: 'Resolution',
      authorizedAmount: 'Authorized Amount',
    },
    PaymentResponse: {
      successfulPayment: 'Payment Processed Successfully!',
      waitingForPayment: 'Waiting for payment...',
      creatingOrder: 'Creating Order...',
      paymentCancelled: 'Payment Cancelled',
      retryPayment: 'Retry Payment',
      invalidPaymentRequest:
        'Invalid Payment Request, Contact IT \n If this error persists, process the order through the website',
      invalidCloverCredentials:
        'Your Clover credentials have expired. Please log in again. \n Your progress will be saved.',
      verifyCloverStatus:
        'Verify the status of your Clover device. \n If you can connect, please retry payment. \n Otherwise, select a different device from the menu.',
      deviceInaccessible: 'Device is inaccessible, please select another device and retry payment.',
      deviceUnavailable: 'Device Unavailable',
      deviceUnavailableBody:
        'Device is unavailable, turned off, or currently handling a different request. Please choose a different device. If your device is unresponsive, alert a manager or IT for assistance.',
      paymentTimeOut: 'Payment timed out.',
      pleaseTryAgain: 'Please try again.',
      checkStatus: 'Check Status',
      viewOrder: 'View Order',
      clearDeviceOperations: 'Clear Device Operations',
      followPrompts: 'Follow the prompts on the terminal.',
      connected: 'Connected',
      disconnected: 'Disconnected',
      deviceStatus: 'Device Status',
      orderCreated: 'Order Created',
    },
    MonthlyPayments: {
      payAtOwnPace: 'Pay at Your Own Pace',
      selectAtCheckout: 'Select _____ at Checkout',
      financing: 'Monthly payments with as low as 0% APR financing',
    },
    OrderTotal: {
      subTotal: 'Subtotal',
      total: 'Order total',
      payLater: 'pay as low as',
      duration: 'month',
      refundTotal: 'Refund Total',
      exchangeRefund: 'Exchange refund',
      checkRefund: 'Check Refund',
      checkRefundMessage:
        '*Please contact Customer Care after creating order so they can create and send a check to the customer.',
    },
    PromoCode: {
      promoCode: 'Promo Code',
      hide: 'Hide',
      add: 'Add',
      enterCode: 'Enter promo code',
      apply: 'Apply',
      generateCode: 'Generate Code',
      createDiscountCode: 'Create Discount Code',
      discountType: 'Discount Type',
      discountMethod: 'Discount Method',
      discountAmount: 'Discount Amount',
      discountQuantity: 'Quantity',
      percentage: 'Percentage',
      dollar: 'Dollar',
      coupon: 'Coupon',
      discountName: 'Discount Name',
      generate: 'Generate',
      back: 'Back',
      copiedToClipboard: 'Copied To Clipboard',
      discountedProduct: 'Discounted Product',
      failedToAddPromo: 'Failed to add promo to the cart!',
      successfullyAddedToCart: 'Successfully added promo to the cart!',
      removeError: 'Something went wrong. Could not remove promo code.',
      percentCheck:
        'Coupon Generation requires: Coupon reason when discount is more than 25% of cart total',
      outletPermissions: 'You must be a manager to create a discount more than 50%',
    },
    ResetCart: {
      ExitCart: {
        title: 'Warning',
        message: 'Exiting the cart will reset all shipping methods to "Carry Out".',
        message2:
          'Select “Continue” if you would like to stay within this transaction but exit the cart and reset the shipping methods.',
        message3:
          'Select “Load a New Cart” if you would like to save and close this cart to start a new transaction. Choose this option after sending a Draft Order to reset the cart.',
        cancel: 'Cancel',
        continue: 'Continue',
        loadNewCart: 'Load a New Cart',
      },
      note: 'NOTE: ',
      updateShippingMethodsLastNote:
        'Exiting the cart and deleting some items will reset shipping methods to Carry Out. Please select shipping methods after adding all items to the cart and ensure all shipping methods are correct before checking out.',
      removeRejuvenateTitle: 'Are you sure you want to remove the ',
      removeRejuvenateText:
        'Removing this item will disqualify the cart from free in home set up and reset all shipping methods to Carry Out. Do you want to continue?',
      removeRejuvenateErrorMessage:
        'The shipping methods were reset, but something went wrong while removing the ',
      removingLineItemError: 'Something went wrong while removing the line item.',
      verifyShippingMethodsNote:
        'Please ensure all shipping methods are correct before checking out.',
      goBackToCart: 'Go back to Cart',
    },
  },
  CartCard: {
    edit: 'Edit',
  },
  ChipLabel: {
    bestSeller: 'BEST SELLER',
    onSale: 'ON SALE',
    specialOffer: 'Special Offer',
  },
  Page404: {
    default: 'Page',
    notFound: 'Not Found.',
    somethingWrong: 'Something went wrong',
  },
  Footer: {
    orders: 'Orders',
    promotions: 'Promotions',
    shop: 'Shop',
    drafts: 'Drafts',
  },
  Greeting: {
    greeting: 'Hello,',
  },
  Header: {
    logo: {
      alt: 'Purple logo',
    },
  },
  Location: {
    loading: 'Loading...',
    selectStore: 'Select Store',
    selectLocation: 'Select Store Location',
    save: 'Save',
    notAvailable: 'N/A',
  },
  Login: {
    greeting: 'HELLO.',
    instructions: 'Click the button below to login.',
    loginBtn: 'LOGIN WITH OKTA',
    logo: {
      alt: 'Purple logo',
    },
    redirect: 'To log in, you will be directed to',
  },
  Logout: {
    logoutBtn: 'Logout',
  },
  Orders: {
    Refunds: {
      lineItem: 'Line Item ID',
      quantity: 'Quantity',
      amountRefunded: 'Amount Refunded',
      amountAvailable: 'Amount Available',
      amountToRefund: 'Amount to Refund',
      amountPaid: 'Amount Paid',
      sku: 'Sku',
      productName: 'Product',
      warning: `Product refund amount tracking is a new feature of MPOS.
         Orders placed prior to June 2023 may not reflect accurate product refund amounts.
         For placed before June 2023 refer to the payment methods for currently available (and previous) refund amounts.`,
      totalAmountToRefund: 'Total Amount to Refund:',
      partialRefund: 'Partial Refund',
      amountsError: 'One or more refund amounts are invalid',
      createPartialRefund: 'Create Partial Refund',
      totalPayments: 'Payment Method Totals',
      refundReason: 'Refund Reason',
      required: 'Required',
      notTracked: 'Not Tracked',
      giftCard: 'GIFT CARD',
      giftCardRefunds: 'For gift card refunds, contact Customer Care.',
      refundFailed: 'Refund Failed',
      returnToOrder: 'Return to Order Details',
      tryAgain: 'You can try again, or contact Customer Care for assistance.',
      backToOrder: 'Back To Order',
      createAnotherRefund: 'Create Another Refund',
      refundFailedContactCCare: 'If a refund failed, contact Customer Care for further assistance',
      paymentId: 'Payment ID',
      failedToRefund: 'Failed to Refund',
      successfullyRefunded: 'Successfully Refunded',
      processingRefund: 'Processing Refund...',
      transactionSummary: 'Transaction Summary',
      invalidReturnMethod: 'One or more selected return method amounts are invalid',
      totalAmountError: 'Total amount to refund must match the Payment Method Total',
      paymentMethod: 'Payment Method',
      paymentAmount: 'Payment Amount',
    },
    Returns: {
      return: 'Return',
      available: 'Available',
      productName: 'Product',
      sku: 'SKU',
      price: 'Price',
      amountRefunded: 'Amount Refunded',
      amountAvailable: 'Amount Available',
      returnQuantity: 'Return Quantity',
      refundStatus: 'Refund Status:',
      shippingStatus: 'Shipping Status:',
      startReturn: 'Start Return',
      quantityError: 'You can only return the available quantity of items.',
      dialogTitle: 'Please complete this form to submit a return.',
      previousReturns: 'Previous Returns',
      review: 'Review',
      submit: 'Submit',
      success: 'Success',
      returnSuccessfulMessage: 'Your return was submitted and is being processed.',
      failed: 'Your return failed to submit. Please try again.',
      date: 'Date:',
      quantity: 'Quantity:',
      refundAmount: 'Refund Amount:',
      notAvailable: 'N/A',
      noPreviousReturns: 'No Previous Returns',
      returnCreation: 'Return Creation',
      pending: 'Pending',
      close: 'Close',
      cancel: 'Cancel',
      next: 'Next',
      back: 'Back',
      finish: 'Finish',
      itemCondition: 'Item Condition',
      used: 'Used/Opened',
      unopened: 'Unopened',
      returnReason: 'Return Reason',
      provideReason: 'Please provide a reason',
      physicalReturn: 'Confirmation that items have physically been returned to store',
      conditionAndReason: 'Condition & Reason',
      customerInfo: 'Customer Info',
      returnConfirmation: 'Confirmation',
      returnFormConfirmation: 'Please confirm the information below.',
      whatConditionItemsBeingReturned: 'What condition are the item(s) being returned?',
      wellsFargoMessage:
        'ORDER ON HOLD. Finance application is processing. Modifications to the order are prohibited within the first 48 hours. Submit shipping method and address changes to Retail Inventory and all other requests in After Hours/WF Delay form.',
      reasons: {
        elsewhere: 'Buying Elsewhere/Shopping Around',
        change: 'Change Color/Size/Style(non-mattress)',
        changedMind: 'Changed Mind',
        uncomfortable: 'Generally Uncomfortable',
        needMoney: 'Need Money',
        notBetter: 'Not Better than Old Product',
        expectation: 'Not What Expected',
        spouse: 'Spouse Refuses',
        upsetDelivery: 'Upset with Delivery',
        upsetPurple: 'Upset with Purple',
        other: 'Other',
      },
      formFields: {
        agentName: 'Agent Name',
        agentEmail: 'Agent Email',
        storeKey: 'Store Key',
        orderNumber: 'Order Number',
        customerEmail: 'Customer Email',
        customerName: 'Customer Name',
        storeAddress: 'Store Address',
        suite: 'Ste',
        city: 'City',
        state: 'State',
        zip: 'Zip',
        country: 'Country',
      },
    },
    Orders: {
      orderDetails: 'Order Details',
      orderNumber: 'Order Number:',
      searchById: 'Order Number, Email...',
      storeSelect: 'Select Store',
      orderTypeSelect: 'Select Order Type',
      all: 'All',
      allStores: 'All Stores',
      exchangeType: 'Exchange',
      returnType: 'Has Return',
      hasExchange: 'Has Exchange',
      draftType: 'Completed Draft',
      storeOrders: 'Store Orders',
      orderSearch: 'Order Search',
      search: 'Search',
    },
    OrderSearch: {
      types: {
        return: 'Return',
        exchange: 'Exchange',
        completedDraft: 'Completed Draft',
        originalExchange: 'Original Exchange',
      },
    },
    EmailModal: {
      title: 'Email',
      send: 'Send',
      sendTo: 'The confirmation email will be sent to: ',
      emailSent: 'Email sent.',
      sendError: 'Something went wrong: Email was not sent. Try again.',
    },
    CustomerDetails: {
      customerDetails: 'Customer Details',
      customerName: 'Customer Name',
      customerEmail: 'Customer Email',
      creditCard: {
        alt: 'Visa',
      },
      endingIn: 'Ending In ',
      paymentMethod: 'Payment Method',
      phone: 'Phone Number',
      shippingAddress: 'Shipping Address',
      method: 'Method: ',
      notAvailable: 'N/A',
    },
    Actions: {
      availableActions: 'Available Actions',
      email: 'Email',
      exchange: 'Exchange',
      return: 'Return',
      printThermalReceipt: 'Reprint Thermal Receipt',
      viewReceipt: 'View Receipt',
      printReceiptSuccess: 'Successfully Printed Receipt',
      printReceiptFailure: 'Receipt printing failed!',
      wellsFargoMessage:
        'ORDER ON HOLD. Finance application is processing. Modifications to the order are prohibited within the first 48 hours. Submit shipping method and address changes to Retail Inventory and all other requests in After Hours/WF Delay form.',
      exchangeInstructions: {
        createExchangeOrder: 'Create Exchange Order',
        warning:
          'You are about to create an exchange order related to the currently selected order',
        continue: 'By continuing, you will first create an exchange order cart.',
        addItems: 'Add items to the cart like a standard order.',
        checkout:
          'When you are ready to checkout, select which items will be exchanged from the original order',
        totalGreater:
          'If the total is greater than the value of the exchanged items, you will be prompted to take payment for the difference and create an exchange order.',
        totalLess:
          'If the total is less than or equal to the value of the exchanged items, you will be prompted to create an exchange order.',
        singlePaymentOnly: 'Please note that exchange orders only allow a single payment method.',
        proceed: 'Are you ready to proceed?',
        creatingExchangeCart: 'Creating exchange order cart...',
        createExchangeCartFailure: 'Unable to create an exchange order',
        exchangeCartWarning: 'This is an exchange cart for order #',
      },
      exchangeCredit: 'Exchange Credit',
      exchangeItems: 'Exchange Items',
    },
    ItemDetails: {
      itemImg: {
        alt: 'Item image',
      },
    },
    OrderDetails: {
      contact: {
        email: 'Email',
        text: 'Text',
      },
      orderDate: 'Order Date:',
      received: 'Received:',
      salesRep: 'Sales Representative: ',
      notAvailable: 'Not Available',
      status: 'Status:',
    },
    OrderData: {
      orderBreakdown: 'Order Breakdown',
      orderDetails: 'Order Details',
      orderNumber: 'Order Number',
      orderDate: 'Order Date',
      salesRep: 'Sales Representative',
      paymentMethod: 'Payment Method',
      moreDetails: 'more details',
      fulfillmentMethod: 'Fulfillment Method',
      itemStatus: 'Status',
      itemStore: 'Store',
      notAvailable: 'N/A',
      fulfillmentStatusUpdateFailed: 'Failed to update line item fulfillment status',
      fulfillmentStatusNoLineItem: 'Could not find updated line item',
      fulfillmentStatuses: {
        initial: 'Initial',
        awaiting: 'Awaiting',
        processing: 'Processing',
        fulfilled: 'Fulfilled',
      },
    },
    OrderList: {
      details: 'Details',
      noOrdersFound: 'No Orders Found',
      orders: 'Orders',
      orderNumber: 'Order Number',
      orderType: 'Order Type',
      purchaseDate: 'Purchase Date',
      grandTotal: 'Grand Total',
      grandTotalTooltip: 'Grand total before exchanges or returns.',
      customerName: 'Customer Name',
      customerEmail: 'Customer Email',
      location: 'Location',
      type: 'Type',
    },
    OrderTotals: {
      discounts: 'Discounts',
      giftCards: 'Gift Cards',
      orderTotal: 'Order total',
      promos: 'Promo(s)',
      shipping: 'Shipping',
      subtotal: 'Subtotal',
      taxes: 'Taxes',
      appliedDiscounts: 'Discount Codes',
    },
    Discounts: {
      usedDiscounts: 'Used Discount Codes',
      none: 'None',
    },
    RefundInfo: {
      refunds: 'Refunds',
      refunded: 'Refunded',
      available: 'Available',
      paymentTransactions: 'Payment Transactions',
      refundAmount: 'Refund Amount: ',
      transactionType: 'Transaction Type: ',
      transactionStatus: 'Transaction status: ',
      transferred: 'Transferred',
    },
    ReturnBox: {
      return: 'Return',
      exchange: 'Exchange',
    },
    RefundModal: {
      payment: 'Payment',
      refund: 'Refund',
      agent: 'Agent',
      refundPreview: 'Refund Preview',
      refundAmount: 'Refund Amount',
      refundReason: 'Refund Reason',
      confirm: 'Confirm',
      paymentInfo: 'Payment Info',
      paymentType: 'Payment Type',
      lastFour: 'Last Four #',
      paymentAmount: 'Payment Amount',
      appliedRefund: 'Applied Refund Amount',
      enterAmount: 'Enter Payment Refund Amount',
      amountError: 'Please enter an amount that is less than your total amount and greater than 0.',
      dollarAmount: 'Dollar Amount',
      paymentDate: 'Payment Date',
      giftCardRefund: 'Please contact customer support for gift card refunds.',
      refundError: 'Sorry Something Went Wrong.',
      refundSuccess: 'Your Refund Was Successful.',
      tryAgain: 'Try Again',
      instructions: 'To process a refund, please contact Customer Care',
      patience:
        'We appreciate your continued patience and support as new functionality is added to MPOS',
    },
    ReturnInfo: {
      modalTitle: 'Returns',
      startRefund: 'Start Return',
      cancel: 'Cancel',
    },
  },
  ProductCard: {
    addToCart: 'Add to Cart',
    hasLimitedAvailability: 'Product has limited availability. Please check pickup options',
    duplicateItemInCart:
      'This same SKU is already in the cart from another shop. Please remove it from your cart before adding this one.',
  },
  Shop: {
    ShopHeader: {
      breadcrumbs: {
        root: 'Categories',
      },
      heading: 'Shop',
      headingOutlet: 'Outlet Shop',
      variant: 'variant',
    },
    Variants: {
      error: 'Product',
    },
    Categories: {
      imgAlt: 'category image',
    },
    Products: {
      imgAlt: 'product image',
      error: 'Category',
      notFound: 'No Products Found',
      filterProducts: 'Filter Products',
      filters: {
        used: 'Used Products',
        factorySeconds: 'Factory Seconds',
        retailOnly: 'Retail Only',
        floorModels: 'Floor Models',
        all: 'All Products',
      },
    },
    PDP: {
      details: {
        shippingDetails: 'Shipping Details',
        shippingDimensions: 'Shipping Dimensions',
        shippingWeight: 'Weight',
      },
      inventory: {
        update: 'Update Inventory',
        lastUpdate: 'Last Updated:',
        error: 'Something went wrong while updating inventory!',
      },
      variants: {
        variants: 'Variants',
        size: 'Size',
        color: 'Color',
        pillow_size: 'Pillow Size',
        pillow_height: 'Pillow Height',
        duvet_type: 'Duvet Type',
        gender: 'Gender',
        depth: 'Depth',
        feel: 'Mattress Feel',
      },
      imgAlt: 'product image',
      errors: {
        missingOption:
          'The option you selected is not currently available. Please try another option.',
      },
      addToCart: {
        success: 'Item added to cart!',
      },
    },
  },
  UserMenu: {
    toolTip: 'User',
  },
  Services: {
    autoRefundError: 'Auto Refund requires: cartId, refundAmount, and reason',
    refundPreviewError: 'Refund preview requires: cartId, refundAmount, and reason',
    refundFail: 'Refund failed try again',
  },
  Employees: {
    admin: {
      heading: 'Add Users',
      description: 'Enter one user per line, in the format:',
      format: '[Full Name], [Email Address], [EMPLOYEE | MANAGER]',
      exampleA: 'Example A: John Doe, john.doe@example.com, EMPLOYEE',
      exampleB: 'Example B: Jane Doe, jane.doe@example.com, MANAGER',
      listUsers: 'List of Users',
      results: 'Results',
      success: 'Successfully Added!',
      submit: 'Submit',
      invalidName: 'Invalid name',
      invalidEmail: 'Invalid email',
      invalidRole: 'Invalid role',
    },
  },
  ErrorLogs: {
    heading: 'Error Logs',
    instructions:
      'Instructions: Use this button to copy the error logs and email them to the MPOS team at: mpos_feedback@purple.com .',
    viewLogs: 'View Error Logs',
    copied: 'Copied to clipboard!',
  },
  Troubleshooting: {
    viewGuide: 'Troubleshooting Guide',
    faqs: [
      {
        instructions: `
## Quick Summary

- **[CCare](mailto:cc-helprequest@purple.com)**: any issues with orders, order creation, invisible orders, payments, etc
- **[Retail Inventory](https://onpurple.atlassian.net/servicedesk/customer/portal/32/create/496)**: any issues with inventory
- **[IT](https://onpurple.atlassian.net/servicedesk/customer/portal/11)**: new access requests or issues with hardware
- **[MPOS Dev Team](mailto:mpos_feedback@purple.com)**: issues with the actual app (white screen, crashing, errors, things not working as expected when doing something directly in the app)
        `,
      },
      {
        instructions: `
## What do I do if I come across a blank white screen or the app crashes?

The white screen (or "white screen of death") happens when the application crashes for some reason. To help the _MPOS Dev Team_ determine the cause of the crash and fix the problem, it would be extremely helpful to send the error logs to the dev team.

There are a couple different ways to send the error logs.

### Send the error logs through the feedback form

When you submit feedback through the feedback form, the error logs will be automatically attached and sent with the feedback.

### Send a screenshot of the error logs

If the feedback form does not work for some reason, you can also view the logs directly and send a screenshot instead. To do this:

1. Click on the "profile" icon in the top navigation (where the logout button lives)
2. Click on "View Error Logs"
3. Take a screenshot
4. Attach the screenshot either to an email or to a Teams message and send it to: [mpos_feedback@purple.com](mailto:mpos_feedback@purple.com)
          `,
      },
      {
        instructions: `
## What do I do if I suspect or see an "invisible order"?

All "invisible orders" or transactions that are double charged should be reported in an email **immediately** to [cc-helprequest@purple.com](mailto:cc-helprequest@purple.com) (with your DM on Customer Care). Any refunds that are not received by the customer within 3-5 business days should also be reported to this email. Please include as much of the information below to expedite the process. Customer Care has agreed to a 24-hour max resolution time period. If your issue does not get resolved in that time frame, please forward the email to [r-michelle.j@purple.com](mailto:r-michelle.j@purple.com).

- Customer name:
- Customer email:
- Exact order amount:
- Date of charge on account:
- Payment method (CC, PayPal, Affirm, etc.,):
- Loan ID for Affirm (if applicable):
- Name on loan or credit card:
- Last four #s of credit card (if applicable):

All other issues that involve Customer Care can be called into the _Contact Center_ for a resolution. If you do run into any issues that Customer Care cannot fix, please reach out to the _Owned Retail MPOS Returns_ Teams chat for guidance. This teams chat should be used as the last option to report any errors.

Please keep in mind that Customer Care will be able to help during business hours Mon-Fri. Any issues reported over the weekend will be resolved Monday.
          `,
      },
      {
        instructions: `
## I came across an issue that Customer Care can't fix

All issues that involve Customer Care can be called into the Contact Center for a resolution. However, if you do run into any issues that Customer Care cannot fix, please reach out to the _Owned Retail MPOS Returns_ Teams chat for guidance. This teams chat should be used as the last option to report any errors.

Please keep in mind that Customer Care will be able to help during business hours Mon-Fri. Any issues reported over the weekend will be resolved Monday.
        `,
      },
    ],
  },
};

export default copyText;
