import axios from 'dataAccess/middleware/axiosInstance';
import { logError } from 'utils/errorUtils';
import getAccessToken from 'utils/getAccessToken';
import locationUtil from 'utils/storeLocation';

const getPaymentById = async (orderId: string) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/payments/${orderId}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    logError({
      method: 'getPaymentById',
      errorInfo: error,
      message: error?.message,
      source: 'api/payments',
    });
    return 'no order found';
  }
};

const deletePendingPayment = async ({ chargeId, cartId }) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/pending-payment/${chargeId}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.delete(uri, config);
    return result.data;
  } catch (error) {
    logError({
      method: 'deletePendingPayment',
      errorInfo: error,
      message: error?.message,
      source: 'api/payments',
    });
    throw error;
  }
};
const addPendingPayment = async (payment: any, cartId: string) => {
  const hasRequiredParams = !!cartId;
  const oktaToken = getAccessToken();
  if (hasRequiredParams) {
    try {
      const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/pending-payment/${payment.id}`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      const body = {
        paymentDetails: payment,
      };

      const response = await axios.post(uri, body, config);
      return response;
    } catch (error) {
      logError({
        method: 'addPendingPayment',
        errorInfo: error,
        message: error?.message,
        source: 'api/payments',
      });
    }
  }

  throw new Error('addPendingPayment requires: payment, cartId');
};

const voidPayment = async ({ paymentId, cartId, paymentProvider }) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/payments/${paymentId}/void`;
    let merchantId = locationUtil.getLocationStorage();
    merchantId = merchantId?.custom?.fields?.cloverMerchantId;
    const storeKey = locationUtil.getStoreKey();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const body = {
      cartId,
      paymentProvider,
      merchantId,
      storeKey,
    };
    const result = await axios.post(uri, body, config);
    return result.data;
  } catch (error) {
    logError({
      method: 'voidPayment',
      errorInfo: error,
      message: error?.message,
      source: 'api/payments',
    });
    return 'payment not voided';
  }
};

const updatePendingPayment = async ({ cartId, chargeId, paymentId, state }) => {
  try {
    const hasRequiredParams = !!cartId && !!chargeId;
    const oktaToken = getAccessToken();
    if (!hasRequiredParams) {
      throw new Error('updatingPendingPayment requires: chargeId, cartId');
    }
    const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/pending-payment/${chargeId}`;
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const body = {
      paymentDetails: {
        paymentId,
        state,
      },
    };

    const response = await axios.put(uri, body, config);
    return response;
  } catch (error) {
    logError({
      method: 'updatePendingPayment',
      errorInfo: error,
      message: error?.message,
      source: 'api/payments',
    });
    throw error;
  }
};

export default {
  getPaymentById,
  addPendingPayment,
  voidPayment,
  deletePendingPayment,
  updatePendingPayment,
};
