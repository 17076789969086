// import Fetch_Promise from '../../utils/fetch_promise';
import axios from 'dataAccess/middleware/axiosInstance';
import getAccessToken from 'utils/getAccessToken';
import storeLocation from 'utils/storeLocation';
import { logError } from 'utils/errorUtils';
import type {
  CreateCartParams,
  SearchCartParams,
  draftCustomerInfo,
  //   UpdateCartParams,
  // eslint-disable-next-line import/extensions
} from './interfaces/cartEndpointInterfaces';

// Microservice agnostic base url for DMI endpoints

/**
 * Get Cart - Retrieves a cart based on the cart id
 * @param cartId the id of the cart to retrieve
 * @returns A cart or undefined if nothing can be found
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}/get
 */
const getCart = async (cartId: string) => {
  const hasRequiredParams = !!cartId;
  const oktaToken = getAccessToken();
  if (hasRequiredParams && oktaToken) {
    try {
      const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };

      const response = await axios.get(uri, config);
      return response;
    } catch (error) {
      logError({
        method: 'getCart',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart',
      });
    }
  }

  return null;
  // throw new Error('Getting cart requires: cartId');
};

/**
 * createCart - Creates a cart associated to an existing customer
 * @param params the customer email and id
 * @returns a newly created cart or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1/post
 */
const createCart = async (params: CreateCartParams) => {
  const requirementsA = params.anonymousId && params.customerEmail;
  const requirementsB = params.customerId && params.customerEmail;
  const oktaToken = getAccessToken();

  if (requirementsA || requirementsB) {
    try {
      // let oktaToken = Fetch_Promise.Get_Okta_Token();
      const uri = `${process.env.REACT_APP_MS_URL}/carts/`;
      const reqBody = { ...params };
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      const response = await axios.post(uri, JSON.stringify(reqBody), config);

      return response;
    } catch (error) {
      logError({
        method: 'createCart',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart',
      });
    }
  }

  throw Error('Create cart requires: customerEmail and customerId');
};

/**
 * createAnonymousCart - Creates a cart for guest checkout
 * @param anonymousId a generated uuid to help anonymous checkout
 * @returns a new cart or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1/post
 */
const createAnonymousCart = async () => {
  try {
    // const hasRequiredParams = !!anonymousId;
    const oktaToken = getAccessToken();
    // if (hasRequiredParams) {
    const storeKey = storeLocation.getStoreKey();
    if (storeKey) {
      const uri = `${process.env.REACT_APP_CART_MS_URL}/carts/in-store/${storeKey}`;
      const reqBody = {};
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      const response = await axios.post(uri, JSON.stringify(reqBody), config);
      return response.data;
    }
    throw new Error('Cart creation requires a store key');
  } catch (error) {
    logError({
      method: 'createAnonymousCart',
      errorInfo: error,
      message: error?.message,
      source: 'api/cart',
    });
  }
  throw Error('create anonymous cart requires: anonymousId');
};

/**
 * searchCart - Searches carts based on CT formatted filter params
 * @param params query string params (requires CT Formatting)
 * @returns A list of matching carts
 * @link https://onpurple.github.io/carts-ms/#/paths/~1/get
 */
const searchCart = async (params?: SearchCartParams) => {
  const oktaToken = getAccessToken();
  try {
    const uri = `${process.env.REACT_APP_MS_URL}/carts/query`;
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
      params,
    };
    const response = await axios.get(uri, config);

    return response.data;
  } catch (error) {
    logError({
      method: 'searchCart',
      errorInfo: error,
      message: error?.message,
      source: 'api/cart',
    });
  }
  throw Error('Problem searching cart');
};

/**
 * updateCart - Updates a cart via CT update actions
 * @param cartId the CT cart id
 * @param params the CT formatted update actions
 * @returns An updated cart object or undefined
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{cartId}~1update/post
 */
const updateCart = async (cartId: string, params) => {
  const hasRequiredParams = !!cartId && !!params && params.actions.length > 0;
  const oktaToken = getAccessToken();
  if (hasRequiredParams) {
    try {
      const uri = `${process.env.REACT_APP_CART_MS_URL}/carts/${cartId}/update`;
      const reqBody = { ...params };
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };

      //     // TODO: create interfaces to match response shape
      //     // let response = await Fetch_Promise.Post(uri, reqBody, oktaToken);
      const response = await axios.post(uri, JSON.stringify(reqBody), config);

      return response.data;
    } catch (error) {
      logError({
        method: 'updateCart',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart',
      });
    }
  }

  throw Error('Update cart requires: cartId, updateActions');
};

const markCartAsDraft = async (cartId: string) => {
  try {
    const oktaToken = getAccessToken();
    const uri = `${process.env.REACT_APP_CART_MS_URL}/carts/${cartId}/draft`;
    const reqBody = { draft: true };
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const response = await axios.patch(uri, JSON.stringify(reqBody), config);

    return response.data;
  } catch (error) {
    logError({
      method: 'markCartAsDraft',
      errorInfo: error,
      message: error?.message,
      source: 'api/cart',
    });
  }
  throw Error('Problem marking cart as a draft');
};

const setDraftInfo = (cartId: string, draftData: draftCustomerInfo) => {
  try {
    const oktaToken = getAccessToken();
    const uri = `${process.env.REACT_APP_CART_MS_URL}/carts/${cartId}/draft-data`;
    const reqBody = { ...draftData };
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    return axios.post(uri, JSON.stringify(reqBody), config);
  } catch (error) {
    logError({
      method: 'setDraftInfo',
      errorInfo: error,
      message: error?.message,
      source: 'api/cart',
    });
  }
  throw Error('Problem setting draft info');
};

const markAsExchangeCart = async (cartId: string, orderId: string, originalOrderNumber: string) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const oktaToken = getAccessToken();
    const uri = `${process.env.REACT_APP_CART_MS_URL}/carts/${cartId}/exchange`;
    const reqBody = { originalOrderId: orderId, originalOrderNumber };
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const response = await axios.patch(uri, JSON.stringify(reqBody), config);

    return response.data;
  } catch (error) {
    logError({
      method: 'markAsExchangeCart',
      errorInfo: error,
      message: error?.message,
      source: 'api/cart',
    });
    throw error;
  }
};

const updateExchangeItems = async (cartId: string, exchangeItems: any) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const oktaToken = getAccessToken();
    const uri = `${process.env.REACT_APP_CART_MS_URL}/carts/${cartId}/exchange-items`;
    const jsonItems = JSON.stringify(exchangeItems);
    const reqBody = { exchangeLineItems: jsonItems };
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const response = await axios.patch(uri, JSON.stringify(reqBody), config);

    return response.data;
  } catch (error) {
    logError({
      method: 'updateExchangeItems',
      errorInfo: error,
      message: error?.message,
      source: 'api/cart',
    });
    throw error;
  }
};

const sendDraftOrderEmail = async (params: any) => {
  try {
    const oktaToken = getAccessToken();
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/orders/draft`;
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const body = params;
    const response = await axios.post(uri, body, config);

    return response.data;
  } catch (error) {
    logError({
      method: 'sendDraftOrderEmail',
      errorInfo: error,
      message: error?.message,
      source: 'api/cart',
    });
  }
  throw Error('Problem sending draft order email');
};

const setAttributionSalesAssociate = async ({ cartId, salesAssociateId }: any) => {
  try {
    const oktaToken = getAccessToken();
    const uri = `${process.env.REACT_APP_CART_MS_URL}/carts/${cartId}/attribution`;
    const body = { salesAssociateId };
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const response = await axios.post(uri, body, config);

    return response.data;
  } catch (error) {
    logError({
      method: 'setAttributionSalesAssociate',
      errorInfo: error,
      message: error?.message,
      source: 'api/cart',
    });
    throw error;
  }
};

export default {
  createCart,
  createAnonymousCart,
  getCart,
  markAsExchangeCart,
  searchCart,
  updateCart,
  setDraftInfo,
  markCartAsDraft,
  sendDraftOrderEmail,
  updateExchangeItems,
  setAttributionSalesAssociate,
};
