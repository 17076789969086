import { React, useState, useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { bool, string, shape } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { copyText } from 'language';
import clover from 'dataAccess/api/clover.ts';
import cloverUtils from 'utils/clover';
import { useCart, useSetCart, useSetOpenCheckoutStepper } from 'contexts';
import { createCart } from 'utils/cart';
import { addCartToHistory, updateProcessedCart } from 'utils/cartHistory';
import orders from 'dataAccess/api/orders.ts';
import ReceiptModal from 'components/ReceiptModal/ReceiptModal';
import exchanges from 'dataAccess/api/exchanges.ts';
import financialCalculators from 'utils/financialCalculators/financialCalculators';
import sendExchangeEmail from 'utils/exchangeEmailUtils';
import { getAgentName, getAgentEmail } from 'utils/agentUtils';
import SnackbarMessage from 'components/SnackbarMessage/SnackbarMessage';
import OrderFailureDialog from 'components/OrderFailureDialog/OrderFailureDialog';

import MultiPayModal from '../CartFinancials/components/MultiPayModal/MultiPayModal';
import PaymentResponse from '../CartFinancials/components/PaymentResponse/PaymentResponse';
import ChargeAfter from '../CartFinancials/components/ChargeAfter/ChargeAfter';
import DraftStepper from '../DraftStepper/DraftStepper';

const CheckoutButtons = ({ checkRefund, selectedAgent }) => {
  const [paymentResponse, setPaymentResponse] = useState({});
  const [orderResponse, setOrderResponse] = useState();
  const [open, setOpen] = useState(false);
  const [orderOpen, setOrderOpen] = useState(false);
  const [createOrderFailure, setCreateOrderFailure] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [checkoutDisabled, setCheckoutDisabled] = useState(true);
  const [showDraftOrderStepper, setShowDraftOrderStepper] = useState(false);
  const [emptyExchange, setEmptyExchange] = useState(false);
  const setOpenCheckoutStepper = useSetOpenCheckoutStepper();
  const history = useHistory();
  const cart = useCart();
  const setCart = useSetCart();
  const { oktaAuth } = useOktaAuth();
  const borderRadius = 6;
  const height = 56;
  const isCartTotalPriceUnder100 = cart?.totalPrice?.centAmount < 10000;

  const clearCart = async () => {
    const newCart = await createCart(
      getAgentEmail(oktaAuth.authStateManager?._authState?.idToken?.claims?.email),
    );
    setCart(newCart);
    addCartToHistory(newCart);
  };

  const createOrder = async (paymentResult) => {
    const order = await orders.createOrderFromCart(cart?.id, paymentResult);
    setOrderResponse(order.data);
    setOpen(false);
    setOrderOpen(true);
    return { order, orderCreated: true };
  };

  const createExchangeOrder = async (params) => {
    const orderResult = await exchanges.createExchangeOrder(params);
    setOrderResponse(orderResult.data);
    setOpen(false);
    setOrderOpen(true);
    return { order: orderResult, orderCreated: true };
  };

  const updateErrorMessage = (error) => {
    if (typeof error === 'string') {
      setErrorMessage(error);
    } else {
      // TODO: get the error message from the error object.
      setErrorMessage(`${copyText.Cart.CheckoutButtons.error} ${JSON.stringify(error)}`);
    }
  };

  const loadOrder = (orderNumber) => {
    setOrderOpen(false);
    history.push({ pathname: `/orders/order/${orderNumber}` });
  };

  const processOrder = async (payment) => {
    try {
      await clover.displayThankYou({ setMessage });
      // create order, if success then clear cart
      if (cart?.is_exchange_order) {
        const params = {
          cartId: cart?.id,
          paymentResponse: payment,
          transactionType: 'charge',
          agentName: getAgentName(oktaAuth.authStateManager?._authState?.idToken?.claims?.name),
          agentEmail: getAgentEmail(oktaAuth.authStateManager?._authState?.idToken?.claims?.email),
        };
        return await createExchangeOrder(params);
      }
      return await createOrder(payment);
    } catch (error) {
      setOpen(false);
      setCreateOrderFailure(true);
      updateErrorMessage(error);
      setMessage(error.message);
      throw new Error('Order creation failed.');
    } finally {
      setTimeout(() => {
        clover.displayWelcome({ setMessage });
      }, 3000);
    }
  };

  const receiptFunction = async (orderResult, payment) => {
    try {
      if (orderResult.orderCreated) {
        await clover.printReceipt(payment.data, orderResult.order.data, setMessage);
        if (cart?.is_exchange_order) {
          await sendExchangeEmail(orderResult.order.data);
        }
        updateProcessedCart(cart.id);
        await clearCart();
      } else {
        throw new Error('Order creation failed.');
      }
    } catch (error) {
      setOpen(false);
      setCreateOrderFailure(true);
      updateErrorMessage(error);
      setMessage(error.message);
    }
  };

  const paymentResponse200 = async (payment) => {
    let orderResult = {};
    try {
      orderResult = await processOrder(payment);
      await receiptFunction(orderResult, payment);
    } catch (error) {
      setMessage(error.message);
    }
  };

  const handlePaymentResponse = async (payment) => {
    switch (payment.status) {
      case 200:
        await paymentResponse200(payment);

        break;
      case 209: {
        setTimeout(() => {
          clover.displayWelcome();
        }, 3000);
        break;
      }

      case 400: {
        setTimeout(() => {
          clover.displayWelcome();
        }, 3000);
        break;
      }
      case 401: {
        break;
      }
      case 415: {
        break;
      }
      case 500: {
        break;
      }
      case 501: {
        break;
      }
      case 503: {
        break;
      }
      case 504: {
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleCashPayment = async () => {
    try {
      setLoading(false);
      setPaymentResponse({ status: 200 });
      setOpen(true);
      let order;

      if (cart.is_exchange_order) {
        const params = {
          cartId: cart?.id,
          paymentResponse: null,
          transactionType: 'refund',
          agentName: getAgentName(oktaAuth.authStateManager?._authState?.idToken?.claims?.name),
          agentEmail: getAgentEmail(oktaAuth.authStateManager?._authState?.idToken?.claims?.email),
        };
        order = await exchanges.createExchangeOrder(params);
        // send exchange email
        await sendExchangeEmail(order.data, checkRefund);
      } else {
        order = await orders.createCashOrder(cart?.id);
      }
      setOpen(false);
      setOrderResponse(order.data);

      setOrderOpen(true);
      await clearCart();
    } catch (error) {
      setOpen(false);
      setCreateOrderFailure(true);
      updateErrorMessage(error);
    }
  };

  const handleRetry = () => {
    setOpen(true);
    if (financialCalculators.getTotalPrice(cart) > 0) {
      handlePaymentResponse(paymentResponse);
    } else {
      handleCashPayment();
    }
  };

  const sendPaymentRequest = async (manualEntry = false) => {
    try {
      const totalPrice = financialCalculators.getTotalPrice(cart);
      if (totalPrice === 0) {
        handleCashPayment();
        return;
      }
      if (cart.id && totalPrice !== 0) {
        setOpen(true);
        setLoading(true);
        const paymentData = {
          cartId: cart.id,
          paymentTotal: totalPrice,
          manualEntry,
          splitPayments: false,
        };
        const result = await clover.paymentRequest(paymentData);
        setLoading(false);
        setPaymentResponse(result);
        handlePaymentResponse(result);
        setLoading(false);
      } else {
        throw new Error(copyText.Cart.CartTools.paymentFailed);
      }
    } catch (error) {
      setOpen(false);
      setCreateOrderFailure(true);
      updateErrorMessage(error);
    }
  };

  const cloverValidation = () => {
    const cloverDevice = cloverUtils.getSessionCloverDevice();
    setCheckoutDisabled(!cloverDevice || cloverDevice === 'select');
  };

  const hasNoExchangeItems = () => {
    if (!cart?.is_exchange_order) return false;
    const exchangeItems = JSON.parse(cart?.exchange_order_line_items ?? '[]');
    return exchangeItems.length === 0;
  };

  const isCheckoutDisabled = () => {
    const isCartEmpty = cart?.lineItems.length === 0;
    const isExchangeOrderEmpty = hasNoExchangeItems();
    const isAgentNotSelected = !selectedAgent || selectedAgent?.email === 'not_set';
    return isCartEmpty || isAgentNotSelected || isExchangeOrderEmpty;
  };

  useEffect(() => {
    cloverValidation();
    window.addEventListener('storage', cloverValidation);
    return () => window.removeEventListener('storage', cloverValidation);
  }, []);

  useEffect(() => {
    if (cart?.is_exchange_order) {
      setEmptyExchange(hasNoExchangeItems());
      setCheckoutDisabled(isCheckoutDisabled());
      cloverValidation();
    }
  }, [cart, selectedAgent]);

  return (
    <>
      <PaymentResponse
        response={paymentResponse}
        open={open}
        loading={loading}
        resendPaymentRequest={() => sendPaymentRequest(true)}
        closeModal={() => setOpen(false)}
      />
      {orderResponse && (
        <ReceiptModal
          open={orderOpen}
          order={orderResponse}
          loadOrder={loadOrder}
          closeModal={() => setOrderOpen(false)}
        />
      )}
      <Button
        variant="outlined"
        size="large"
        sx={{
          mt: 3,
          textTransform: 'none',
          width: '100%',
          borderRadius,
          height,
        }}
        onClick={() => setShowDraftOrderStepper(true)}
        disabled={
          cart?.lineItems.length === 0 || !selectedAgent || selectedAgent?.email === 'not_set'
        }
      >
        <Typography variant="p" component="span">
          {copyText.Cart.CartTools.DraftOrder.sendDraftButton}
        </Typography>
      </Button>
      <Button
        variant="contained"
        size="large"
        sx={{
          mt: 3,
          textTransform: 'none',
          width: '100%',
          borderRadius,
          height,
        }}
        onClick={() => setOpenCheckoutStepper(true)}
        disabled={checkoutDisabled}
      >
        <Typography variant="h3" component="span" color="white">
          {copyText.Cart.CheckoutButtons.proceed}
        </Typography>
      </Button>
      <Box
        sx={{ mt: 1 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {emptyExchange && (
          <Typography variant="caption" color="textSecondary" sx={{ mb: 3 }}>
            {copyText.Cart.CheckoutButtons.noItemsToExchange}
          </Typography>
        )}
        {/* todo: delete next line once the feature is good on production */}
        {/* {canDoChargeAfter() &&  */}
        <>
          <ChargeAfter disabled={!cart?.shippingAddress || isCartTotalPriceUnder100} />
          <Typography variant="caption" color="textSecondary" sx={{ mb: 3 }}>
            {copyText.Cart.CartTools.ChargeAfter.financingAvailable}
          </Typography>
        </>
        {/* } */}
      </Box>
      <OrderFailureDialog
        errorMessage={errorMessage}
        open={createOrderFailure}
        onClose={() => setCreateOrderFailure(false)}
        onRetry={handleRetry}
      />
      <MultiPayModal />
      <DraftStepper
        showDraftOrderStepper={showDraftOrderStepper}
        setShowDraftOrderStepper={setShowDraftOrderStepper}
        setMessage={setMessage}
      />
      <SnackbarMessage message={message} setMessage={setMessage} />
    </>
  );
};

export default CheckoutButtons;

CheckoutButtons.propTypes = {
  checkRefund: bool,
  selectedAgent: shape({
    email: string,
    name: string,
  }),
};

CheckoutButtons.defaultProps = {
  checkRefund: false,
  selectedAgent: null,
};
