import { func, bool, shape, string, arrayOf } from 'prop-types';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { List, ListItem, Typography } from '@mui/material';
import { copyText } from 'language';
import { useCart, useSelectedShop, useSetCart, useSetShowShippingSnackbar } from 'contexts';
import { saveChanges } from 'utils/editCartUtils';
import { filterDuplicateSkus } from 'utils/cart';
import TextLoader from 'components/TextLoader';
import DialogTemplate from 'components/DialogTemplate/DialogTemplate';
import EditCartCard from './components/EditCartCard';

const EditCartModal = ({ product, handleClose, open, setOpen, variants }) => {
  const [loading, setLoading] = useState(false);
  const cart = useCart();
  const setCart = useSetCart();
  const setShowShippingSnackbar = useSetShowShippingSnackbar();
  const [selectedVariant, setSelectedVariant] = useState(product.variant);
  const [filteredVariants, setFilteredVariants] = useState([]);
  const [message, setMessage] = useState('');
  const selectedShop = useSelectedShop();

  const handleSelection = (variant) => {
    setSelectedVariant(variant);
  };

  useEffect(() => {
    setFilteredVariants(filterDuplicateSkus(cart, product, variants));
  }, [open]);

  return (
    <DialogTemplate
      noButton
      setOpen={setOpen}
      message={message}
      setMessage={setMessage}
      dialogTitle={product.name['en-US']}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      testId="edit-cart-modal"
      copy={{
        title: copyText.App.edit,
        cancel: copyText.App.cancel,
        continue: copyText.Cart.CartTools.updateCart,
      }}
      loading={loading}
    >
      <DialogContent>
        <List>
          {filteredVariants.length > 0 &&
            filteredVariants.map((variant) => {
              return (
                <ListItem key={variant.sku}>
                  <EditCartCard
                    productName={product?.name['en-US']}
                    variant={variant}
                    handleSelection={handleSelection}
                    selectedVariant={selectedVariant}
                    channelId={product?.distributionChannel?.obj?.id ?? null}
                  />
                </ListItem>
              );
            })}
        </List>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: 'lightGrayBG', p: 2 }}>
        <Button
          sx={{ m: 1 }}
          disabled={loading}
          variant="contained"
          onClick={() =>
            saveChanges({
              cart,
              product,
              selectedVariant,
              setMessage,
              setCart,
              setLoading,
              setShowShippingSnackbar,
              selectedShop,
            })
          }
        >
          <Typography
            sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }}
            color="white"
            variant="h3"
            component="span"
          >
            <TextLoader text={copyText.Cart.CartTools.updateCart} loading={loading} size={16} />
          </Typography>
        </Button>
      </DialogActions>
    </DialogTemplate>
  );
};

EditCartModal.propTypes = {
  setOpen: func.isRequired,
  handleClose: func.isRequired,
  open: bool.isRequired,
  product: shape({
    variant: shape({
      sku: string.isRequired,
    }).isRequired,
    name: shape({
      'en-US': string.isRequired,
    }).isRequired,
  }).isRequired,
  variants: arrayOf(shape({})).isRequired,
};

export default EditCartModal;
