import axios from 'dataAccess/middleware/axiosInstance';
import clover from 'utils/clover';
import storeLocation from 'utils/storeLocation';
import getAccessToken from 'utils/getAccessToken';
import { v4 as uuidv4 } from 'uuid';
import { logError } from 'utils/errorUtils';

interface OrderApiResult {
  orders: {
    count: number;
    limit: number;
    offset: number;
    results: Array<any>;
    total: number;
  };
}
interface orderFilters {
  sortBy: string;
  sortDirection: string;
  perPage: number;
  page: number;
  to: string;
  from: string;
  storeKey: string;
  customerEmail: string;
  agentEmail: string;
  orderType: string[] | string;
}

interface exchangeOrderItem {
  product_id: string;
  name: string;
  qty: number;
  price: string;
  promo: string;
  image: string;
  total: string;
}

interface exchangeEmailBody {
  agentName: string;
  agentEmail: string;
  customerEmail: string;
  agentNote: string;
  originalOrderNumber: string;
  exchangeOrderNumber: string;
  exchangeDate: string;
  exchangeItems: exchangeOrderItem[];
  returnSubtotal: string;
  returnTaxes: string;
  returnTotals: string;
  subtotal: string;
  orderTotal: string;
  tax: string;
  discounts: string;
  customerFirstName: string;
  newItems: exchangeOrderItem[];
  evenExchange: boolean;
  exchangeDue: boolean;
  exchangeSubtotal: string;
  exchangeTax: string;
  exchangeTotal: string;
  amountDue: string;
  refundCheck: string;
  refundCard: string;
  chargeCard: string;
  storeKey: string;
}

interface returnItem {
  sku: string;
  lineItemId: string;
  quantity: string;
  centAmount: number;
}

export interface returnBody {
  orderNumber: string;
  agentName: string;
  agentEmail: string;
  storeKey: string;
  customerEmail: string;
  customerName: string;
  itemCondition: string;
  returnAmount: number;
  returnReason: string[];
  returnItems: returnItem[];
  physicalReturn: boolean;
}

const emptyOrderResponse: OrderApiResult = {
  orders: {
    count: 0,
    limit: 0,
    offset: 0,
    results: [],
    total: 0,
  },
};

const createCashOrder = async (cartId: string) => {
  try {
    // throw new Error('cash test')
    const uri = `${process.env.REACT_APP_CART_MS_URL}/orders/${cartId}`;
    const paymentId = uuidv4();
    const accessToken = getAccessToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const reqBody = {
      paymentMethod: 'CASH',
      id: paymentId,
    };
    const result = await axios.post(uri, reqBody, config);
    return result;
  } catch (error) {
    logError({
      method: 'createCashOrder',
      errorInfo: error,
      message: error?.message,
      source: 'api/orders',
    });
    throw error;
  }
};

const createOrderFromCart = async (cartId: string, paymentResponse: any) => {
  try {
    const uri = `${process.env.REACT_APP_CART_MS_URL}/orders/${cartId}`;
    const paymentId = paymentResponse.data.payment.id;
    const cloverToken = clover.getCloverAccessToken().token;
    const merchantId = storeLocation.getLocationStorage()?.custom?.fields?.cloverMerchantId;
    const accessToken = getAccessToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const reqBody = {
      paymentMethod: 'CLOVER',
      id: paymentId,
      sessionId: '',
      accessToken: cloverToken,
      merchantId,
    };
    const result = await axios.post(uri, reqBody, config);
    return result;
  } catch (error) {
    logError({
      method: 'createOrderFromCart',
      errorInfo: error,
      message: error?.message,
      source: 'api/orders',
    });
    throw error;
  }
};

const createOrderFromCartMultiplePayments = async (cartId: string) => {
  try {
    const uri = `${process.env.REACT_APP_MS_URL}/orders/${cartId}/multiple-payments`;
    const merchantId = storeLocation.getLocationStorage()?.custom?.fields?.cloverMerchantId;
    const accessToken = getAccessToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const reqBody = {
      sessionId: '',
      merchantId,
      splitPayment: true,
    };
    const result = await axios.post(uri, reqBody, config);
    return result;
  } catch (error) {
    logError({
      method: 'createOrderFromCartMultiplePayments',
      errorInfo: error,
      message: error?.message,
      source: 'api/orders',
    });
    throw error;
  }
};
const getOrderById = async (orderId: string) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_CART_MS_URL}/orders/${orderId}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    logError({
      method: 'getOrderById',
      errorInfo: error,
      message: error?.message,
      source: 'api/orders',
    });
    return {};
  }
};

// TODO: Refactor other methods to use axiosInstance
// - Testing can be done by going to the Orders search, searching for an order, and clicking on the order number
const getByOrderNumber = async (orderNumber: string) => {
  try {
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/orders/number/${orderNumber}`;
    const result = await axios.get(uri);
    return result.data;
  } catch (error) {
    logError({
      method: 'getByOrderNumber',
      errorInfo: error,
      message: error?.message,
      source: 'api/orders',
    });
    return {};
  }
};

const getByEmail = async (email: string, page: number) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/orders?email=${email}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    logError({
      method: 'getByEmail',
      errorInfo: error,
      message: error?.message,
      source: 'api/orders',
    });
    return emptyOrderResponse;
  }
};

const getByStoreKey = async (storeKey: string, page: number) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/orders/store/${storeKey}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      params: { page },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    return emptyOrderResponse;
  }
};

const getTodaysStoreOrders = async (storeKey: string, page: number) => {
  const todaysDate = new Date().toISOString().split('T')[0];
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/orders/store/${storeKey}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        from: todaysDate,
        page,
      },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    logError({
      method: 'getTodaysStoreOrders',
      errorInfo: error,
      message: error?.message,
      source: 'api/orders',
    });
    return emptyOrderResponse;
  }
};

const getFilteredOrders = async (filters: orderFilters) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_MS_URL}/orders/filter`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      params: filters,
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    logError({
      method: 'getFilteredOrders',
      errorInfo: error,
      message: error?.message,
      source: 'api/orders',
    });
    return emptyOrderResponse;
  }
};

const sendEmail = async (email: string, orderId: string) => {
  try {
    const accessToken = getAccessToken();
    const reqBody = { email, orderId };
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/orders/${orderId}/email/confirmation`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const payload = JSON.stringify(reqBody);
    const result = await axios.post(uri, payload, config);
    return result.data;
  } catch (error) {
    logError({
      method: 'sendEmail',
      errorInfo: error,
      message: error?.message,
      source: 'api/orders',
    });
    return error;
  }
};
const sendExchangeEmail = async (orderId, exchangeData: exchangeEmailBody) => {
  try {
    const accessToken = getAccessToken();
    const reqBody = { ...exchangeData };
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/orders/${orderId}/email/exchange`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const payload = JSON.stringify(reqBody);
    const result = await axios.post(uri, payload, config);
    return result.data;
  } catch (error) {
    logError({
      method: 'sendExchangeEmail',
      errorInfo: error,
      message: error?.message,
      source: 'api/orders',
    });
    return error;
  }
};

const getByDate = async (from: string, to: string, page: number) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/orders/byDate`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      params: { from, to, page },
    };
    const result = await axios.get(uri, config);
    return result.data;
  } catch (error) {
    logError({
      method: 'getByDate',
      errorInfo: error,
      message: error?.message,
      source: 'api/orders',
    });
    return emptyOrderResponse;
  }
};

const updateFulfillmentStatus = async (
  orderId: string,
  lineItemId: string,
  fulfillmentStatus: string,
) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_MS_URL}/orders/${orderId}/fulfillmentStatus`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const body = {
      lineItemId,
      fulfillmentStatus,
    };
    const result = await axios.patch(uri, body, config);
    return result.data;
  } catch (error) {
    logError({
      method: 'updateFulfillmentStatus',
      errorInfo: error,
      message: error?.message,
      source: 'api/orders',
    });
    throw error;
  }
};

const createReturn = async (orderId: string, body: returnBody) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_MS_URL}/orders/return/${orderId}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const result = await axios.post(uri, body, config);
    return result;
  } catch (error) {
    logError({
      method: 'createReturn',
      errorInfo: error,
      message: error?.message,
      source: 'api/orders',
    });
    throw error;
  }
};

export default {
  getOrderById,
  getByOrderNumber,
  getByEmail,
  getFilteredOrders,
  createOrderFromCart,
  createCashOrder,
  getByStoreKey,
  getTodaysStoreOrders,
  getByDate,
  sendEmail,
  sendExchangeEmail,
  updateFulfillmentStatus,
  createOrderFromCartMultiplePayments,
  createReturn,
};
